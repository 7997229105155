import "../../styles/About/Story.scss";
import image from "../../images/story.png";
import image1 from "../../images/story1.png";
import image2 from "../../images/story2.png";
import image3 from "../../images/story3.png";
import { FaChevronRight } from "react-icons/fa6";

const Story = () => {
  return (
    <div className="story-back">
      <div className="story">
        <div className="contents">
          <div className="subtitle">Our Story:</div>
          <div className="title">Our Journey: From Vision to Reality</div>
          <div className="text">
            Witness the passion and dedication of our visionary founders as they
            embarked on this entrepreneurial adventure, driven by a shared
            purpose to make a difference in the [your industry or domain]. Step
            by step, our team turned dreams into reality, overcoming obstacles
            and seizing opportunities along the way.
          </div>
          <div className="item">
            <div className="icon-panel">
              <img src={image1} className="icon"></img>
            </div>
            <div className="content-panel">
              <div className="title">Founders' Vision</div>
              <div className="text">
                Highlight the aspirations and passion of the company's founders
                as they laid the groundwork for the journey
              </div>
            </div>
          </div>
          <div className="item">
            <div className="icon-panel">
              <img src={image2} className="icon"></img>
            </div>
            <div className="content-panel">
              <div className="title">Team Collaboration</div>
              <div className="text">
                Emphasize the importance of teamwork and collaboration in
                turning the vision into a reality.
              </div>
            </div>
          </div>
          <div className="item">
            <div className="icon-panel">
              <img src={image3} className="icon1"></img>
            </div>
            <div className="content-panel">
              <div className="title">Global Reach</div>
              <div className="text">
                If applicable, mention your company's expansion into new markets
                or regions, showcasing its growth and international presence.
              </div>
            </div>
          </div>
          <div className="button">
            <div className="text">
              Learn More
              <span style={{ fontSize: "14px" }}>
                <FaChevronRight />
              </span>
            </div>
          </div>
        </div>
        <img src={image} className="image" />
      </div>
    </div>
  );
};

export default Story;
